<template>
  <div>
    <v-card class="mt-3" flat>
      <v-card-title class="mb-6">
        <v-text-field
          v-model="search"
          class="pt-0 mt-0"
          width="300"
          hide-details
          single-line=""
          placeholder="Busca pelo id da venda"
          append-icon="search"
          outlined
        />
        <v-spacer />
        <mf-button label="Adicionar Ação" icon="settings" @click="openSalesTypeDialog" />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          :server-items-length="maxRecords"
          :options.sync="options"
          :headers="headers"
          :items="activeProductsData"
          :search="search"
        >
          <template v-slot:[`item.type`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <v-icon class="mr-2" x-small :color="mapColor(item.type)">mdi-brightness-1</v-icon>{{ mapSaleType(item.type) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseDate(item.created_at) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.total_items`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.items.length || 0 }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.sale_total`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ getSaleTotal(item.items) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.observation`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <v-tooltip v-if="item.observation" max-width="350" top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="limitText" v-bind="attrs" v-on="on">{{ item.observation }}</span>
                  </template>
                  <span>{{ item.observation }}</span>
                </v-tooltip>
                <span v-else> - </span>
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.contract_link`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <mf-button v-if="item.contract_link" text label="Ver contrato" @click="openContract(item.contract_link)" />
                <span v-else> - </span>
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-menu :close-on-content-click="false">
              <template #activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="saleDetails(item)">
                  <v-list-item-title>{{ 'Detalhe de ' + labelDetails(item.type) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <sales-type-dialog v-if="showSalesTypeDialog" :has-new-sale="haveNewSale" @input="closeSalesTypeDialog" @select-action="selectActionType" />
    <details-dialog v-if="detailsDialog" :sale-id="saleId" :dialog="detailsDialog" @close="closeDialog" />
  </div>
</template>

<script>
import { QUERY_GET_FRANCHISE_SALES, QUERY_GET_FRANCHISE_AVAILABLE_PRODUCT, MUTATION_UNSET_CHURN } from '@/modules/franchises/graphql'
import { getSaleTypeByValue } from '@/constants/salesTypes'
export default {
  components: {
    SalesTypeDialog: () => import('../../../../components/atomic-components/molecules/SalesTypeDialog.vue'),
    DetailsDialog: () => import('../dialogs/DetailsDialog.vue')
  },
  data: () => ({
    loading: false,
    showSalesTypeDialog: false,
    search: '',
    options: {},
    maxRecords: 0,
    activeProductsData: [],
    saleId: null,
    detailsDialog: false,
    retailerData: {},
    haveNewSale: false
  }),
  computed: {
    headers() {
      return [
        {
          text: 'ID da venda',
          value: '_id',
          align: 'center'
        },
        {
          text: 'Data Ação',
          value: 'created_at',
          align: 'center'
        },
        {
          text: 'Responsável',
          value: 'owner',
          align: 'center'
        },
        {
          text: 'Tipo de Ação',
          value: 'type',
          align: 'center'
        },
        {
          text: 'Quantidade de Itens',
          value: 'total_items',
          align: 'center'
        },
        {
          text: 'Total da Ação',
          value: 'sale_total',
          align: 'center'
        },
        {
          text: 'Observação',
          value: 'observation',
          align: 'center'
        },
        {
          text: 'Contrato',
          value: 'contract_link',
          align: 'center'
        },
        {
          text: '',
          value: 'options',
          align: 'center'
        }
      ]
    }
  },
  apollo: {
    franchises: {
      query: QUERY_GET_FRANCHISE_SALES,
      fetchPolicy: 'network-only',
      variables() {
        return {
          franchise_id: this.$route.params.id,
          pagination: {
            page: this.options.page || 1,
            pageSize: this.options.itemsPerPage || 10,
            search: this.search || null,
            sort: this.options.sortBy || [],
            sortDirection: (this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))) || []
          }
        }
      },
      update({ franchiseSales: { count, data } }) {
        this.maxRecords = count
        this.activeProductsData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    },
    verifyIfHasNewSale: {
      query: QUERY_GET_FRANCHISE_AVAILABLE_PRODUCT,
      fetchPolicy: 'network-only',
      variables() {
        return {
          franchise_id: this.$route.params.id,
          filters: {
            active: true
          },
          pagination: {
            page: 1,
            pageSize: 9999,
            search: '',
            sort: ['_id'],
            sortDirection: 'ASC'
          }
        }
      },
      update({ franchiseAvailableProducts: { count, data } }) {
        this.haveNewSale = !(count === 0 || data.length === 0)
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  mounted() {
    this.clientId = this.$route.params.id
  },
  methods: {
    selectActionType(data) {
      this.$router.push({ name: 'create-sale-franchise', query: data })
    },
    saleDetails(sale) {
      this.saleId = sale._id
      this.detailsDialog = true
    },
    labelDetails(type) {
      return getSaleTypeByValue(type)?.label
    },
    async openSalesTypeDialog() {
      let active = this.$attrs.franchise.active === null ? true : this.$attrs.franchise.active
      if (!active) {
        const confirmation = await this.$confirm({
          title: 'Aviso',
          message: 'A rede está inativa. Para realizar uma nova ação, é necessário ativá-la. Deseja ativá-la agora?',
          confirmText: 'Sim',
          cancelText: 'Não'
        })
          .then(() => true)
          .catch(() => false)
        if (!confirmation) return
        try {
          await this.$apollo.mutate({
            mutation: MUTATION_UNSET_CHURN,
            variables: {
              franchise_id: this.$attrs.franchise._id
            },
            context: {
              uri: this.$microservicesUrls['crm']
            }
          })
          setTimeout(() => {
            this.$alert({
              alert_message: `Churn Removido com sucesso`,
              alert_title: 'Sucesso!',
              alert_color: 'success',
              alert_icon: 'mdi-check-circle'
            })
          }, 300)
        } catch {
          this.$alert({
            alert_message: `Ops, parece que houve algum erro`,
            alert_title: 'Erro!',
            alert_color: 'error',
            alert_icon: 'mdi-close-circle'
          })
        }
      }
      this.showSalesTypeDialog = true
    },
    closeSalesTypeDialog() {
      this.showSalesTypeDialog = false
    },
    parseDate(date) {
      return this.$moment(date).format('DD/MM/YYYY - HH:mm:ss')
    },
    parseCurrency(value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })

      return formatter.format(value)
    },
    mapSaleType(type) {
      return getSaleTypeByValue(type)?.label
    },
    mapColor(type) {
      const mapType = {
        new_sale: 'info',
        upsell: 'success',
        downsell: 'error',
        temporary_churn: 'warning',
        revert_temporary_churn: 'success',
        discount: 'red lighten-2',
        cross_sell: 'success'
      }

      return mapType[type]
    },
    closeDialog() {
      this.saleId = null
      this.detailsDialog = false
      this.downsellDetailsDialog = false
    },
    getSaleTotal(items) {
      let total = 0
      items.map(item => {
        if (item.total_value) total += item.total_value
      })

      return this.parseCurrency(total)
    },
    openContract(link) {
      return window.open(link, '_blank')
    },
    showSaleDetails(sale) {
      return (
        sale?.type === 'new_sale' || sale?.type === 'upsell' || sale?.type === 'temporary_churn' || sale?.type === 'cross_sell' || sale?.type === 'discount'
      )
    }
  }
}
</script>
