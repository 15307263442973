var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"width":"300","hide-details":"","single-line":"","placeholder":"Busca pelo id da venda","append-icon":"search","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('mf-button',{attrs:{"label":"Adicionar Ação","icon":"settings"},on:{"click":_vm.openSalesTypeDialog}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"server-items-length":_vm.maxRecords,"options":_vm.options,"headers":_vm.headers,"items":_vm.activeProductsData,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.mapColor(item.type)}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(_vm.mapSaleType(item.type))+" ")],1)],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.parseDate(item.created_at))+" ")])],1)]}},{key:"item.total_items",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.items.length || 0)+" ")])],1)]}},{key:"item.sale_total",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getSaleTotal(item.items))+" ")])],1)]}},{key:"item.observation",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[(item.observation)?_c('v-tooltip',{attrs:{"max-width":"350","top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"limitText"},'span',attrs,false),on),[_vm._v(_vm._s(item.observation))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.observation))])]):_c('span',[_vm._v(" - ")])],1)],1)]}},{key:"item.contract_link",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[(item.contract_link)?_c('mf-button',{attrs:{"text":"","label":"Ver contrato"},on:{"click":function($event){return _vm.openContract(item.contract_link)}}}):_c('span',[_vm._v(" - ")])],1)],1)]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.saleDetails(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s('Detalhe de ' + _vm.labelDetails(item.type)))])],1)],1)],1)]}}],null,true)})],1)],1),(_vm.showSalesTypeDialog)?_c('sales-type-dialog',{attrs:{"has-new-sale":_vm.haveNewSale},on:{"input":_vm.closeSalesTypeDialog,"select-action":_vm.selectActionType}}):_vm._e(),(_vm.detailsDialog)?_c('details-dialog',{attrs:{"sale-id":_vm.saleId,"dialog":_vm.detailsDialog},on:{"close":_vm.closeDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }